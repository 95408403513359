import { breakpoint, MOBILE, LARGE_DESKTOP } from '../util/getBreakpoint';
import { throttle } from 'throttle-debounce';

export default () => {
  const section = document.querySelector('.c-our-people');
  const gridItems = [...document.querySelectorAll('.c-expandable-content')];

  gridItems.forEach((gridItemA) => {
    const buttons = gridItemA.querySelectorAll('.c-expandable-content__btn');

    buttons.forEach((btn) => {
      btn.addEventListener('click', () => {
        gridItemA.style.transform = `translateY(0px)`;
        gridItemA.classList.toggle('c-expandable-content--active');

        gridItems.forEach((gridItemB) => {
          if (gridItemA !== gridItemB) {
            resetOpenItem(gridItemB);

            if (breakpoint !== MOBILE) {
              const indexA = gridItems.indexOf(gridItemA);
              const indexB = gridItems.indexOf(gridItemB);
              const textHeight = gridItemA.querySelector('.tab-content').offsetHeight;
              const yVal = gridItemA.classList.contains('c-expandable-content--active') ? textHeight : 0;
              const gridRowAmount = getGridRowAmount();

              // Moves any items below active Item down
              if (indexB > indexA && (indexB - indexA) % gridRowAmount === 0) {
                gridItemB.style.transform = `translateY(${yVal}px)`;
              }

              section.style.paddingBottom = `${yVal}px`;
            }
          }
        });
      });
    });
  });

  const throttleScroll = throttle(500, resetAllItems);
  let bp = breakpoint;

  window.addEventListener('resize', throttleScroll, true);

  function resetAllItems() {
    if (breakpoint !== bp) {
      gridItems.forEach((item) => {
        item.classList.remove('c-expandable-content--active');
        item.style.transform = `translateY(0px)`;
      });
      section.style.paddingBottom = '0px';
      bp = breakpoint;
    }
  }

  function getGridRowAmount() {
    return breakpoint === LARGE_DESKTOP ? 3 : 2;
  }

  function resetOpenItem(item) {
    if (item.classList.contains('c-expandable-content--active')) {
      item.classList.remove('c-expandable-content--active');
    }
    item.style.transform = `translateY(0px)`;
  }
};
