import { gsap, Power1 } from 'gsap';
const section = document.querySelector('.c-content-panels');
const prevNavItems = [...document.querySelectorAll('.c-content-panels .c-content__prev h3')];
const nextNavItems = [...document.querySelectorAll('.c-content-panels .c-content__next h3')];
const backgroundColours = ['red', 'green', 'blue', 'gold']; // get this from data attribute when linking to cms
const heroCropBG = document.querySelector('.c-hero-crop-cta');

const prevArrows = document.querySelectorAll('.js-panel-prev');
const nextArrows = document.querySelectorAll('.js-panel-next');

const panelCount = document.querySelectorAll('.c-content-panels__slide').length;

let activeIndex = 0;
let prevIndex = 0;
let isTransitioning = false;

prevNavItems.forEach((item, index) => handleClick(item, index));
nextNavItems.forEach((item, index) => handleClick(item, index));

prevArrows.forEach((prevArrow) => bindPrevArrow(prevArrow));
nextArrows.forEach((nextArrow) => bindNextArrow(nextArrow));

export function addContentPanelScrollEvents() {
  changePanel(null, activeIndex);
}

function handleClick(navItem, index) {
  navItem.addEventListener('click', () => {
    if (index === prevIndex) return;
    if (isTransitioning) return;
    const direction = index > activeIndex ? 'down' : 'up';

    activeIndex = index;
    changePanel(prevIndex, activeIndex, direction);
    prevIndex = activeIndex;
  });
}

function changePanel(prev, active, direction) {
  if (isTransitioning) {
    return;
  }

  isTransitioning = true;

  animateContent(prev, false, direction);
  animateContent(active, true, direction);
  handleNavItems(direction);
  handleBGColor();
  handleArrowDisable();

  setTimeout(() => isTransitioning = false, 1000);
}

function handleNavItems(direction) {
  prevNavItems.forEach((item, index) => {
    if (direction === 'down' && index === activeIndex - 1) animateNav(item, true, true);
    else if (direction === 'up' && index === activeIndex) animateNav(item, false, false);
    if (index < activeIndex) resetNavItem(item);
    if ((direction === 'down' && index >= activeIndex) || (direction === 'up' && index >= activeIndex + 1))
      item.style.display = 'none';
  });

  nextNavItems.forEach((item, index) => {
    if (direction === 'down' && index === activeIndex) animateNav(item, false, true);
    else if (direction === 'up' && index === activeIndex + 1) animateNav(item, true, false);
    if (index > activeIndex) resetNavItem(item);
    if ((direction === 'down' && index < activeIndex) || (direction === 'up' && index <= activeIndex))
      item.style.display = 'none';
  });
}

function handleBGColor() {
  backgroundColours.forEach((color) => {
    if (heroCropBG) heroCropBG.classList.remove(`u-bg-${color}`);
    section.classList.remove(`u-bg-${color}`);
  });

  section.classList.add(`u-bg-${backgroundColours[activeIndex]}`);
  if (heroCropBG) heroCropBG.classList.add(`u-bg-${backgroundColours[activeIndex]}`);
}

function animateContent(index, animateIn) {
  if (index === null) return;

  const anim = gsap.timeline();
  const sectionContent = section.querySelectorAll('.c-content')[index];
  const header = sectionContent.querySelector('.c-content__text-header');
  const text = sectionContent.querySelector('.c-content__text-body');
  const image = sectionContent.querySelector('.c-content__image');
  const navArrows = sectionContent.querySelector('.c-content__nav-arrows');
  const xStart = animateIn ? '-5%' : '0%';
  const xEnd = animateIn ? '0%' : '-5%';
  const opacityStart = animateIn ? 0 : 1;
  const opacityEnd = animateIn ? 1 : 0;
  const delay = animateIn ? 0.4 : 0;

  anim
    .fromTo(
      header,
      0.4,
      { autoAlpha: opacityStart, y: animateIn ? 15 : 0 },
      { autoAlpha: opacityEnd, y: animateIn ? 0 : 15, ease: Power1.easeOut },
      delay
    )
    .fromTo(
      text,
      0.4,
      { autoAlpha: opacityStart, x: xStart },
      { autoAlpha: opacityEnd, x: xEnd, ease: Power1.easeOut },
      delay + 0.15
    )
    .fromTo(
        image,
        0.4,
        { autoAlpha: opacityStart, x: xStart },
        { autoAlpha: opacityEnd, x: xEnd, ease: Power1.easeOut },
        delay + 0.3
    )
    .fromTo(
        navArrows,
        0.4,
        { autoAlpha: opacityStart, x: xStart },
        { autoAlpha: opacityEnd, x: xEnd, ease: Power1.easeOut },
        delay + 0.3
    );

}

function animateNav(item, fadeIn, fadeUp) {
  const anim = gsap.timeline();
  const opacityStart = fadeIn ? 0 : 1;
  const opacityEnd = fadeIn ? 1 : 0;
  const visibility = fadeIn ? 'visible' : 'hidden';
  const callback = fadeIn ? 'onStart' : 'onComplete';
  const yStart = getNavItemYAxisValues(fadeIn, fadeUp).yStart;
  const yEnd = getNavItemYAxisValues(fadeIn, fadeUp).yEnd;

  anim
    .fromTo(
      item,
      { autoAlpha: opacityStart, y: yStart },
      { autoAlpha: opacityEnd, y: yEnd, ease: Power1.easeOut },
      0
    )
    .eventCallback(callback, () => {
      item.style.visibility = visibility;
    });
}

function getNavItemYAxisValues(fadeIn, fadeUp) {
  if (fadeUp) {
    if (fadeIn) return { yStart: 30, yEnd: 0 };
    else return { yStart: 0, yEnd: -30 };
  } else {
    if (fadeIn) return { yStart: -30, yEnd: 0 };
    else return { yStart: 0, yEnd: 30 };
  }
}

function resetNavItem(item) {
  item.style.display = 'inline-block';
  item.style.opacity = '1';
  item.style.visibility = 'visible';
  item.style.transform = 'translate(0, 0)';
}

function bindPrevArrow(prevArrow) {
  prevArrow.addEventListener('click', () => {
    previousPanel();
  });
}

function bindNextArrow(nextArrow) {
  nextArrow.addEventListener('click', () => {
    nextPanel();
  });
}

function nextPanel() {
  if (isTransitioning) return;
  if (activeIndex === (panelCount - 1)) return;

  activeIndex = activeIndex + 1;
  changePanel(prevIndex, activeIndex, 'down');
  prevIndex = activeIndex;
}

function previousPanel() {
  if (isTransitioning) return;
  if (activeIndex === 0) return;

  activeIndex = activeIndex - 1;
  changePanel(prevIndex, activeIndex, 'up');
  prevIndex = activeIndex;
}

function handleArrowDisable() {
  prevArrows.forEach((prevArrow) => {
    if (activeIndex === 0) {
      prevArrow.classList.add('inactive');
    } else {
      prevArrow.classList.remove('inactive');
    }
  });

  nextArrows.forEach((nextArrow) => {
    if (activeIndex === (panelCount - 1)) {
      nextArrow.classList.add('inactive');
    } else {
      nextArrow.classList.remove('inactive');
    }
  });
}
